



































































































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery } from "@/store";

export default Vue.extend({
  data() {
    return {
      dispatch,
      loading: false,
      form: this.$form.createForm(this),
      productList: [],
      producerList: [],
      fields: [
        {
          title: "中文名",
          dataIndex: "name_cn",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入中文名",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "英文名",
          dataIndex: "name_en",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入英文名",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        }
      ],
      columns: [
        {
          title: "SN 码",
          dataIndex: "sn_code",
          sorter: true
        },
        {
          title: "防伪码",
          dataIndex: "security_code"
        },
        {
          title: "产品名称",
          dataIndex: "product_info",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "制造商",
          dataIndex: "producer_info",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "生产日期",
          dataIndex: "date_of_manufacture"
        },
        {
          title: "查询次数",
          dataIndex: "times"
        },
        {
          title: "创建时间",
          dataIndex: "id",
          scopedSlots: { customRender: "time" },
          sorter: true
        }
      ]
    };
  },
  computed: {
    ...mapState("SNCodes", {
      list: "list",
      query: "query",
      count: "count"
    })
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .SNCodesGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onReset() {
      this.form.resetFields();
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering,
        status: (filters.status && filters.status[0]) || this.query.status
      });
    }
  },
  mounted() {
    this.getList(this.query);
    dispatch.SNCodesGetProductList().then(res => {
      this.productList = res.results;
    });
    dispatch.SNCodesGetProducerList().then(res => {
      this.producerList = res.results;
    });
  }
});
